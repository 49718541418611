import React from "react"
import { Styled, Flex } from 'theme-ui'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from '../components/item'
import Accordion from '../components/Accordion'


const FaqPage = () => {

  const items = [
    {
      summary: 'What is window tint?',
      detail: '<p>Window tint is a type of window film with a darkening or color-changing factor. It comes in many different varieties, shades, and levels of quality. Our window tint is nano ceramic, the highest quality on the market.<p>'
    },
    {
      summary: 'What are the benefits of Window Tint?',
      detail: '<ul><li>Ultraviolet light (UV) protection</li><li>Fade reduction</li><li>Safety</li><li>Privay and security</li><li>Comfort and cooling</li><li>Energy savings</li></ul>'
    },
    {
      summary: 'What does nano ceramic mean?',
      detail: '<p>Nano ceramic is one of the most advanced, effective window tint technologies available to date. It uses nanotechnology to suspend tiny, invisible ceramic particles in multiple layers of film. This method provides almost-total protection from the sun with or without tinting, and doesn’t change color over time.</p>'
    },
    {
      summary: 'Will Raptect window tint change color?',
      detail: '<p>No. Some lower-quality dyed window films change from black to purple after a few months to a few years of UV exposure. Since Raptect’s tints are protected by tiny ceramic particles, no amount of sunlight will affect the shade.</p>'
    },
    {
      summary: 'Can window tint reduce interior fading?',
      detail: '<p>Yes. Whether inside a home or car, quality window tint can keep furnishings, rugs, and upholstery looking new. UV rays aren’t just dangerous to humans - they can seriously fade fabrics and damage paint over time. Window tint with a high UV reduction rating can slow down this process almost to a complete stop.</p>'
    },
    {
      summary: 'Can window tint increase safety and security?',
      detail: '<p>Yes. It can keep cracked or broken windows from shattering, and can even discourage smash-and-grab burglars.</p>'
    },
    {
      summary: 'Does buying a lighter tint mean less UV protection?',
      detail: '<p>No. All Raptect window tints are 99% effective against UV. While a higher percentage (lighter tint) film will not keep an interior quite as cool as a darker one, it provides the same protection against the sun’s harmful rays.</p>'
    },
    {
      summary: 'What do window tint percentages mean?',
      detail: '<p>The percentages on window tint refer to the amount of visible light they let through. This means that 70% tint will be mostly clear, with a minor (30%) darkening effect. 10%, on the other end of the spectrum, will block most visible light and look black or nearly-black from the outside.</p>'
    },
    {
      summary: 'Is window tint helpful year-round, or only in summer?',
      detail: '<p>Certain tint types, including nano ceramic, are beneficial all year round. Raptect tint keeps heat inside in the winter and deflects it in the summer. It can keep down both your heating and cooling bills, and can help keep a car comfortable no matter the season.</p>'
    },
    {
      summary: 'Can window tint be applied to the outside of a window? ',
      detail: '<p>No. Window tint should always be applied to the inside of a window to ensure optimal performance and a sleek appearance.</p>'
    },
    {
      summary: 'How is window tint installed?',
      detail: 'Professional installation begins with properly cleaning windows and removing any prior films or treatments. Technicians then measure and cut the tint slightly larger than the size of each window, apply it to the inside from bottom to top using an application solution, and finally trim it for an imperceptible look.'
    },
    {
      summary: 'How long does professional automotive tinting take?',
      detail: 'Professional tinting can take anywhere from 30 minutes to a few hours. The timing usually depends on the treatments (cleaning, stripping old tint, etc) that your windows need before tint can be applied. After application, it’s recommended to wait at least 24 hours before washing or rolling windows down.'
    },
    {
      summary: 'Will auto window tint impair my view of the road?',
      detail: 'No. Although it’s important to beware that certain shades are illegal to have on your windshield, all Raptect tints are designed to darken without hindering visibility.'
    },
    {
      summary: 'How long will Raptect window tint last?',
      detail: 'Raptect nano ceramic tints can last 5-10+ years on autos, depending on where and how they’re installed. Residential and commercial building tint can last decades.'
    },
    {
      summary: 'Are all auto window tints legal?',
      detail: 'Not all tints are legal everywhere. Each U.S. state has its own laws regarding auto tint - which windows can be tinted, and the maximum darkening factor. Always educate yourself on your state’s regulations before installing auto tint to avoid an unexpected ticket.'
    },
    {
      summary: 'Will window tint keep my glass from breaking?',
      detail: 'Yes and no. Technically speaking, it will not actually stop glass from breaking. But it can keep it from shattering. Window tint is extremely effective at holding broken shards together and keeping them from harming people when accidents occur.'
    }
  ]

  return (
    <Layout reverseNav>
      <SEO title="Raptect - Frequently Asked Questions" />
      <Container pt={['80px', '220px']} pb={[8, 13]} sx={{ textAlign: 'center'}}>
      
        <Flex sx={{ justifyContent: 'center', textAlign: 'center', marginBottom: [4, 8] }}>
          <Styled.h1>Frequently Asked Questions</Styled.h1>
        </Flex>
        

        <Accordion>
          {items.map(({ summary, detail }) => (
            <Accordion.Item key={summary}>
              <Accordion.Collapsed id={summary}>
              {summary}
              </Accordion.Collapsed>
              <Accordion.Expanded>{detail}</Accordion.Expanded>
            </Accordion.Item>
          ))}
        </Accordion>

      </Container>
    </Layout>
  )
}
 

export default FaqPage
